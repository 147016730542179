.svg-logo-row {
  z-index: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: black;
}

.svg-logo-left-wall {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 50vw;
  height: 100vh;
  transition: transform 0.7s ease-in-out;

  /* Debug visualization */
  /* background-color: burlywood; */
}

.svg-logo-left-wall-after {
  transform: translate(-28vw);
  /* transform: translate(-43vw); */
}

.svg-logo-right-wall {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 50vw;
  height: 100vh;
  transition: transform 0.7s ease-in-out;

  /* Debug visualization */
  /* background-color: aquamarine; */
}

.svg-logo-right-wall-after {
  transform: translate(28vw);
  /* transform: translate(43vw); */
}

.svg-logo-left {
  /* position: inherit; */
  transform-origin: right;
  /* animation: svg-logo-left 1s ease-in-out 3s forwards; */
  /* transform: translate(-205px) perspective(870px) rotateY(45deg); */
  /* transform: translate(-20vw) perspective(870px) rotateY(45deg); */
  /* transform: perspective(870px) rotateY(45deg); */
  transform: perspective(600px) rotateY(55deg);
  transition: all 0.7s ease-in-out;
}

.svg-logo-left-after {
  /* animation: svg-logo-right 1s ease-in-out forwards; */
  /* Original, don't touch */
  /* transform: translate(-620px) perspective(1000px) rotateY(65deg); */
  /* Tablet, still testing */
  /* transform: translate(-420px) perspective(1000px) rotateY(65deg); */
  flex-shrink: 0;
  transform: perspective(1000px) rotateY(65deg);
}

.svg-logo-right {
  position: inherit;
  transform-origin: left;
  /* animation: svg-logo-left 1s ease-in-out 3s forwards; */
  /* transform: translate(205px) perspective(870px) rotateY(-45deg); */
  /* transform: perspective(870px) rotateY(-45deg); */
  transform: perspective(600px) rotateY(-55deg);
  transition: all 0.7s ease-in-out;
}

.svg-logo-right-after {
  /* animation: svg-logo-right 1s ease-in-out forwards; */
  /* Original, don't touch */
  /* transform: translate(620px) perspective(1000px) rotateY(-65deg); */
  /* Tablet, still testing */
  /* transform: perspective(1000px) rotateY(-65deg); */
  flex-shrink: 0;
  transform: perspective(1000px) rotateY(-65deg);
}

/* I think these are using the old animation method, which currently been using transform */
/* @keyframes svg-logo-right {
  100% { transform: translate(620px) perspective(1000px) rotateY(-65deg); }
}

@keyframes svg-logo-left {
  100% { transform: translate(-620px) perspective(1000px) rotateY(65deg); }
} */

.svg-logo-middle {
  z-index: 1;
  position: absolute;
  transform: scaleY(0.5);
}

#top-left, #top-right {
  stroke-dasharray: 410;
  stroke-dashoffset: 410;
  animation: line 1s ease-in-out forwards;
}

#middle {
  stroke-dasharray: 462;
  stroke-dashoffset: 462;
  animation: line 1s ease-in-out 1s forwards;
}

#bottom-left, #bottom-right {
  stroke-dasharray: 410;
  stroke-dashoffset: 410;
  animation: line 1s ease-in-out 2s forwards;
}

@keyframes line {
  to {
    stroke-dashoffset: 0;
  }
}

@media only screen and (min-width: 500px) {
  .svg-logo-left {
    transform: perspective(870px) rotateY(45deg);
  }
  .svg-logo-left-after {
    transform: perspective(1000px) rotateY(65deg);
  }
  .svg-logo-right {
    transform: perspective(870px) rotateY(-45deg);
  }
  .svg-logo-right-after {
    transform: perspective(1000px) rotateY(-65deg);
  }
}

@media only screen and (min-width: 768px) { 
  .svg-logo-left {
    flex-shrink: 0;
  }
  .svg-logo-right {
    flex-shrink: 0;
  }
  .svg-logo-middle {
    transform: scaleY(1);
  }
}

@media only screen and (min-width: 992px) {
  .svg-logo-left-wall-after {
    transform: translate(-28vw);
  }
  
  .svg-logo-right-wall-after {
    transform: translate(28vw);
  }
}

@media only screen and (min-width: 1536px) {
  .svg-logo-left-wall-after {
    transform: translate(-430px);
  }
  .svg-logo-right-wall-after {
    transform: translate(430px);
  }
}