/** The Global CSS for wrappers */
.row {
  width: 75%;
  margin: auto;
  max-width: 768px;
  padding: 10px 10px;
  /* display: block; */
  position: relative;
  z-index: 2;
  color: white;
  /* backdrop-filter: blur(3px); */
  background-color: rgba(0, 0, 0, 0.8);
}

/* .row {
  Original
  padding: 20px 20px;
  padding: 60px 60px;
  max-width: 768px;
} */

.row h2, .row h3, .row h4, .row a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.row p, .row li, .row th, .row td {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 100;
  font-size: 20px;
}

.row h2 {
  font-size: 55px;
  font-weight: 800;
  text-align: center;
  padding-top: 80px;
  margin-top: 0px;
}

.row h3 {
  font-size: 25px;
}

.row h4 {
  font-weight: 300;
  font-style: italic;
}

.space-between-wrapper h3 {
  margin-bottom: 5px;
}

.space-between-wrapper h4 {
  margin-top: 0px;
}

.mid-title {
  margin-top: 2em;
  margin-bottom: 0em;
  font-size: 28px !important;
  font-weight: 600 !important;
}

article {
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.3s ease-out;
  padding: 20px 0px;
}

.scroll-reveal {
  opacity: 1;
  transform: none;
}

/* Slide */

.slide-wrapper {
  display: flex;
  justify-content: center;
}

.slide {
  width: 83vw;
  margin-bottom: 0.5em;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 49vw;
}

.slide-wrapper > img {
  height: 49vw;
}

.white-icon {
  color: white !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

.projects-icon-no-pic {
  padding-top: 0 !important;
}

@media only screen and (min-width: 768px) {
  .row {
    width: 80%;
    max-width: 768px;
    padding: 10px 20px;
    /* display: block; */
    position: relative;
    color: white;
    /* backdrop-filter: blur(3px); */
    /* filter: blur(4px); */
  }


  .space-between-wrapper {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .space-between-wrapper h3 {
    margin-bottom: 1em;
  }

  .space-between-wrapper h4 {
    margin: 1.33em 0;
  }

  .mid-title {
    margin-bottom: 0.5em !important;
  }

  .slide {
    width: 63vw !important;
    max-width: 576px !important;
  }

  .each-slide > div {
    height: 40vw !important;
    max-height: 376px !important;
  }

  .slide-wrapper > img {
    height: 40vw !important;
    max-height: 376px !important;
  }
}

@media only screen and (min-width: 992px) {
  .row {
    margin: auto;
    width: 50%;
    max-width: 768px;
    padding: 0px;
    /* display: block; */
    position: relative;
    color: white;
    backdrop-filter: blur(3px);
  }
}
